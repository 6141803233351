export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZHVqbWExNjA4IiwiYSI6ImNscnlxZTRvMzFtaHYyaW11ZDQ1cTRhczYifQ.NNWHqyeKHUH06Pw4-EegTQ";

export const MAP_URL = `https://api.mapbox.com/styles/v1/dujma1608/clx4nwfr4001101pj2bsldsxo/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`;

export const fetchCoordinates = async (address: string) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      const coordinates = data.features[0]?.geometry?.coordinates;
      if (coordinates && coordinates.length >= 2) {
        const lon = coordinates[0];
        const lat = coordinates[1];
        return { lat, lon };
      }
    }
    throw new Error("Coordinates not found for the address");
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    return null;
  }
};
