import { Store } from "react-stores";
import { IChargerStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { getMarketsToformattedString } from "../helpers/dataHelper";
import { IChargerWithConnectors } from "../interfaces/ICharger";
import { downloadCSV } from "../helpers/csvHelper";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ChargerStore = new Store<IChargerStore>({
  chargers: [],
  chargersSize: 0,
  chargerWithConnectors: null,
  chargersOnboardingStatusCount: null,
  onboardingConnectorsForCharger: [],
});

export const handleGetChargers = async (
  companyMarketIds,
  pageIdx: number = 1,
  pageSize: number = 10,
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = getMarketsToformattedString(companyMarketIds);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/GetChargers${marketsString}&PageIndex=${pageIdx}&PageSize=${pageSize}${sortingField
        ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
        : ""
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    data.data.forEach((el) => {
      el.latitude = (Math.round(el.latitude * 1000) / 1000).toFixed(3);
      el.longitude = (Math.round(el.longitude * 1000) / 1000).toFixed(3);
    });
    ChargerStore.setState({
      ...ChargerStore.state,
      chargers: data.data,
      chargersSize: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetConnectorsForCharger = async (chargerId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Connectors/GetConnectorsByChargerId/${chargerId}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    if (response.ok) {
      const data = await response.json();
      ChargerStore.setState({
        ...ChargerStore.state,
        chargerWithConnectors: { chargerId: chargerId, connectors: data },
      });
      return {
        chargerId: chargerId,
        connectors: data,
      } as IChargerWithConnectors;
    } else {
      return null;
    }
  } catch (e) {
    console.log("Error: ", e);
    return null;
  }
};


export const handleGetChargersOnboardingStatusCount = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/GetChargersOnboardingStatusCount`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetChargersByOnboardingStatus = async (
  onboardingStatus: string,
  pageIdx: number = 1,
  pageSize: number = 10,
  sortingField: string = "",
  sortingDescending: boolean = true
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/GetChargersByOnboardingStatus?OnboardingStatusEnum=${onboardingStatus}&pageIndex=${pageIdx}&pageSize=${pageSize}${sortingField
        ? `&SortingCriteria.Field=${sortingField}&SortingCriteria.Descending=${sortingDescending}`
        : ""
      }`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    data.data.forEach((el) => {
      el.latitude = (Math.round(el.latitude * 1000) / 1000).toFixed(3);
      el.longitude = (Math.round(el.longitude * 1000) / 1000).toFixed(3);
    });
    ChargerStore.setState({
      ...ChargerStore.state,
      chargers: data.data,
      chargersSize: data.totalCount,
    });
    return data.data;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetOnboardingConnectorsForCharger = async (
  chargerId: number
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Connectors/GetOnboardingConnectorsByChargerId?id=${chargerId}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    let returnValue = data.map((connector) => {
      return {
        connectorId: connector.connectorId,
        connectorTypeId: connector.connectorTypeId || undefined,
        connectorType: connector.connectorType || "",
        pricingGroupId: connector.pricingGroupId || undefined,
        pricingGroup: connector.pricingGroup || "",
        maxPower: connector.maxPower || undefined,
      };
    });
    ChargerStore.setState({
      ...ChargerStore.state,
      onboardingConnectorsForCharger: returnValue,
    });
    return returnValue;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleGetConnectorTypes = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/ConnectorTypes/GetConnectorTypes`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleUpdateOnboardingConnector = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Connectors/UpdateConnectorOnboarding`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleCreateCharger = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/CreateCharger`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleFinishOnboardingCharger = async (chargerId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/FinishOnboardingCharger?chargerId=${chargerId}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const GetDetailedChargerLog = async (id: string) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    // Get current time in ISO format (UTC)
    const stopDateTime = new Date().toISOString();
    // Get time 24 hours before current time in ISO format (UTC)
    const startDateTime = new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000
    ).toISOString();

    const data = JSON.stringify({
      id,
      stopDateTime,
      startDateTime,
    });

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/GetChargerLog`,
      {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtString,
        },
      }
    );

    const responseData = await response.json();

    downloadCSV(responseData, "ChargerLog");
  } catch (e) {
    console.log("Error: ", e);
  }
};
