import React, { useState, useEffect, useRef } from "react";
import moment from "moment/moment";
import {
  ActiveStore,
  handleGetActiveSessions,
} from "../../../stores/activeStore";
import { closeConnection, startConnection } from "../../../helpers/signalRHelper";
import { useStore } from "react-stores";
import "./ActiveSession.css";
import Popup from "../../../shared/popup/Popup";
import Spinner from "../../../shared/spinner/Spinner";
import Toast from "../../../shared/toast/Toast";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import MarketDropdown from "../../../shared/marketDropdown/MarketDropdown";
import { IMarketDropdown } from "../../../interfaces/IMarket";
import { ISessionActive } from "../../../interfaces/ISession";

const ActiveSessions = () => {
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [selected, setSelected] = useState<number>(0);
  const [searched, setSearched] = useState<string>("");
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [sort, setSort] = useState({ field: "", descending: true });

  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const { totalCount } = useStore(ActiveStore);
  const [dataTransfered, setDataTransfered] = useState(false);

  const tableHeaders: string[] = [
    "Start Time",
    "Charging Session ID",
    "Market",
    "Duration",
    "Electricity Consumed",
    "Car Battery",
    "Current Bill",
    "Connector ID"
  ];

  const tableRowOrder: string[] = [
    "timeStart",
    "id",
    "companyMarketName",
    "duration",
    "electricityConsumed",
    "currentCarBattery",
    "totalPrice",
    "connectorId"
  ];

  const [toastText, setToastText] = useState<string>("");
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [stopChargingPopup, setStopChargingPopup] = useState<boolean>(false);
  const [stopChargingTimeout, setStopChargingTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [activeSessions, setActiveSessions] = useState<ISessionActive[]>([] as ISessionActive[]);
  const [deletedOrInserted, setDeletedOrInserted] = useState(false);

  let clockTicking: NodeJS.Timeout | null = null;

  useEffect(() => {
    fetchDataFromApi();

    return (() => {
      connection && closeConnection(connection)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, searched, selectedMarkets, sort, deletedOrInserted]);

  const fetchDataFromApi = async () => {
    const fetchSessions = async () => {
      try {
        let sessions = await handleGetActiveSessions(
          selectedMarkets.map((market) => market.id),
          paging.page,
          paging.perPage,
          searched,
          sort.field,
          sort.descending
        );
        sessions ? setActiveSessions(sessions) : setActiveSessions([])
        return sessions || [];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedMarkets.length > 0) {
      let data = await fetchSessions();
      await handleSignalRConnection(data);
      data && setDataTransfered((prev) => !prev);
    }
  }

  useEffect(() => {
    startClock();

    return () => {
      clockTicking && clearInterval(clockTicking);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTransfered]);

  const startClock = () => {
    if (clockTicking) clearInterval(clockTicking);

    clockTicking = setInterval(() => {
      setActiveSessions((prevSessions) => {
        return prevSessions.map((session) => {
          const dur = moment.duration(session.duration).add(1, "second");
          return {
            ...session,
            duration: moment.utc(dur.asMilliseconds()).format("HH:mm:ss"),
          };
        });
      });
    }, 1000);
  };

  const handleSignalRConnection = async (sessions) => {
    try {
      console.log("Setting signalR");
      let conn = await startConnection(`${PUBLIC_BASE_URL}hubs/sessions`);

      await conn
        .invoke("SubscribeActiveSessions")
        .catch((err) => console.error("Subscription error:", err));

      conn.on("newSessionAdded", async (sessionInfo) => {
        setDeletedOrInserted(prev => !prev);
      });

      sessions.forEach(async (session) => {
        await conn
          .invoke("SubscribeB2B", session.id)
          .catch((err) => console.error("Subscription error:", err));
      });

      conn.on("receiveSessionInfo", (sessionInfo) => {
        console.log("Received session info update:", sessionInfo);
      });

      conn.on("updateSession", (updateSessionSignalRDto) => {
        console.log("Session update received:", updateSessionSignalRDto);

        const { sessionId, currentPrice, currentSoC, energyConsumed } =
          updateSessionSignalRDto;

        setActiveSessions((prevSessions) => {
          return prevSessions.map((session) => {
            if (session.id === sessionId) {
              return {
                ...session,
                totalPrice: `${currentPrice.toFixed(2)} ${session.currencyISO}`,
                currentCarBattery: currentSoC>=0 ? `${currentSoC.toFixed(2)}%` : "n/a",
                electricityConsumed: `${Math.abs(energyConsumed.toFixed(2))} kWh`,
              };
            }
            return session;
          });
        });
      });

      conn.on("sessionRemoved", (StopSessionSignalRDto) => {
        console.log("Session removed:", StopSessionSignalRDto);
        setDeletedOrInserted(prev => !prev);
      });

      conn.on("stopSession", handleStopSession);

      setConnection(conn);

      // return () => {
      //   conn.off("newSessionAdded");
      //   conn.off("receiveSessionInfo");
      //   conn.off("updateSession");
      //   conn.off("sessionRemoved");
      // };
    } catch (ex) {
      console.log("Error while establishing signalR connection: " + ex);
    }
  };

  const handleStopSession = (stopSessionSignalRDto) => {
    console.log("Charging session stopped, id:", stopSessionSignalRDto.id);
    setSelected(0);
    setToastStatus(true);
    setToastText("Charging session successfully stopped");
    setToastVisible(true);
    setLoading(false);
    setPaging((prev) => ({ ...prev, page: 1 }));
  };

  const subscribeToStopSession = async (sessionId) => {
    try {
      await connection!
        .invoke("SubscribeToStopSession", sessionId)
        .then(() => {
          console.log("Subscribing to stop sessionId " + sessionId);
          //StopSessionById(sessionId).then(() => setSessionStopped(true));
        })
        .catch((err) => console.error("Subscription error:", err));
    } catch (error) {
      console.error("SignalR connection is not initialized.");
    }
  };

  const handleStopChargingClick = () => {
    if (selected) {
      subscribeToStopSession(selected);
      setStopChargingPopup(false);
      setLoading(true);
      setStopChargingTimeout(setTimeout(() => {
        if (loading) {
          setSelected(0);
          setToastStatus(false);
          setToastText("Charging session didn't stopped properly - try again");
          setToastVisible(true);
          setLoading(false);
        }
      }, 20000));
    }
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending",
      });
    }
  };

  const handleSelection = (id: number) => {
    setSelected((prevSelected) => (prevSelected === id ? 0 : id));
  };

  const searchHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearched(value);
    setPaging((prev) => ({ ...prev, page: 1 }));
  };

  const mainText = () => {
    return (
      <>
        <div className="popout-main-text">
          This action will forcefully stop the charging session on this
          connector ID: {selected}
        </div>
        <div className="popout-other-text">
          Do you want to stop the session?
        </div>
      </>
    );
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch ">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="charging-sessions-history">
            <span>
              <span className="charging-sessions-history-span">
                Charging Sessions/
              </span>
              <span className="charging-sessions-history-span2">Active</span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <div className="relative">
              <img
                className="absolute h-5 left-3 top-2.5"
                src="/icons/table/searchIcon.svg"
                alt=""
              />
              <input
                onChange={searchHistory}
                className="searchInputSessions"
                placeholder="Charging Session ID"
              />
            </div>
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setSelectedMarkets(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              onClick={() => selected && setStopChargingPopup(true)}
              className={`${selected ? "stopChargingButtonActive" : ""
                } stopChargingButton flex flex-row gap-3 items-center justify-center`}
              disabled={!selected}
            >
              Stop Charging
            </button>
          </div>
        </div>
      </div>

      <Table
        tableHeaders={tableHeaders}
        tableRows={activeSessions}
        tableRowOrder={tableRowOrder}
        handleSelection={(id) => handleSelection(id)}
        selected={selected}
        sortColumns={[
          "Start Time",
          "Charging Session ID",
          "Market",
          "Duration",
          "Electricity Consumed",
          "Car Battery",
          "Current Bill",
          "Connector ID"
        ]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        preloadRowNumber={10}
      />
      <Paging
        tableSize={totalCount}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
      {stopChargingPopup && (
        <Popup
          mainText={mainText()}
          confirmFunction={handleStopChargingClick}
          confirmText="Yes"
          closeText="No"
          closeFunction={() => setStopChargingPopup(false)}
        />
      )}
      {loading && <Spinner />}
      {toastVisible && (
        <Toast
          text={toastText}
          closeFunction={() => setToastVisible(false)}
          status={toastStatus}
        />
      )}
    </div>
  );
};

export default ActiveSessions;
