import * as signalR from '@microsoft/signalr';
import { AuthStore } from '../stores/authStore';

export const startConnection = async (url) => {
    let jwtString: string = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt") || "";
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(url,
            {
                withCredentials: false,
                accessTokenFactory: () => jwtString
            }
        )
        .configureLogging(signalR.LogLevel.Information)
        .build();
    await connection.start().catch((err) => console.error('Error while establishing connection', err));
    return connection;
}

export const closeConnection = async (connection) => {
    await connection
        .stop()
        .then(() => console.log('Connection successfully closed.'))
        .catch((err) => console.error('Error while closing the connection', err));
    return connection;
}